<div class="mt-3">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3></h3>
    <div>
      <i *ngIf="!isEditing" class="bi bi-pencil" (click)="toggleEdit()" style="cursor: pointer;"></i>
      <div *ngIf="isEditing">
        <i class="bi bi-check" (click)="save()" style="cursor: pointer; margin-right: 5px;"></i>
        <i class="bi bi-x" (click)="discard()" style="cursor: pointer;"></i>
      </div>
    </div>
  </div>

  <div *ngIf="!isEditing">
    <pre>{{ parsedJson | json }}</pre>
  </div>

  <div *ngIf="isEditing">
    <textarea [(ngModel)]="editedJsonString" class="form-control" rows="10"></textarea>
  </div>
  <button class="btn btn-primary mt-3" (click)="next()">Next</button>
</div>

