<div class="container mt-4">
  <div class="wrapper" >
    <!-- Your content here, can be dynamically added to fill from bottom to up -->
    <div  #chatContainer *ngIf="chatMessages?.messages?.length; else noMessages" class="chat-container" >
        <div *ngFor="let message of chatMessages?.messages" [ngClass]="{'assistant-message': message.messageType === messageTypes.ASSISTANT_MESSAGE, 'human-message': message.messageType === messageTypes.HUMAN_MESSAGE}">
          <app-assistant-message [message]="message" *ngIf="message.messageType==messageTypes.ASSISTANT_MESSAGE" > </app-assistant-message>
          <app-human-message [message]="message" *ngIf="message.messageType==messageTypes.HUMAN_MESSAGE" > </app-human-message>
          <app-tool-selector (functionCallEvent)="callFunction($event)" [message]="message" [chatId]="this.chatId"  *ngIf="message.messageType==messageTypes.TOOL_SELECTOR"> </app-tool-selector>
        </div>
    </div>
    <ng-template #noMessages>
      <p>No messages available.</p>
    </ng-template>
  </div>


  <input [(ngModel)]="humanMessage" [disabled]="!chatId" placeholder="Type your message here..." />
    <button class="btn btn-primary" (click)="sendMessage()">Send</button>



</div>
