import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {JsonPipe} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-rag-final-answer',
  standalone: true,
  imports: [
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './rag-final-answer.component.html',
  styleUrl: './rag-final-answer.component.css'
})
export class RagFinalAnswerComponent {
  @Input() step: any;
  protected readonly STEP_STATUS = STEP_STATUS;
  chatId: string | null = "";

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('chatId');
    });
  }

  backToChat() {
    this.router.navigate([`/chat/${this.chatId}`]);
  }
}
