<div ngbAccordion class="m-4">
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
          <button ngbAccordionButton class="arrowhead-accordion-header">
            <div class="row w-100">
              <div class="col-6">
                  <span><i class="bi bi-bezier mr-2"></i> {{ 'planner.userIntent' | translate}}</span>
              </div>
              <div class="col-6 text-right" >
                <span class="badge bg-success ms-auto">{{'general.success' | translate}}</span>
              </div>
            </div>
          </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-user-intent></app-user-intent>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-plug mr-2"></i> {{'planner.availablePlugins' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-success ms-auto">{{'general.success' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-available-plugins></app-available-plugins>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-exclamation mr-2"></i> {{'planner.initialPlan' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-primary ms-auto">{{'general.inProgress' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-initial-plan></app-initial-plan>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-calendar4-event mr-2"></i> {{'planner.taskDefinition' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-danger ms-auto">{{'general.failed' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-task-definition></app-task-definition>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-clipboard-data mr-2"></i> {{'planner.pluginAction' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-danger ms-auto">{{'general.failed' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-plugin-action></app-plugin-action>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-clipboard-check mr-2"></i> {{'planner.pluginActionResponse' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-danger ms-auto">{{'general.failed' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-plugin-action-response></app-plugin-action-response>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-chat-right-quote mr-2"></i> {{'planner.taskOutcome' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-danger ms-auto">{{'general.failed' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-task-outcome></app-task-outcome>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader >
      <button ngbAccordionButton class="arrowhead-accordion-header">
        <div class="row w-100">
          <div class="col-6">
            <span><i class="bi bi-brightness-high mr-2"></i> {{'planner.revisedPlan' | translate}}</span>
          </div>
          <div class="col-6 text-right" >
            <span class="badge bg-success ms-auto">{{'general.success' | translate}}</span>
          </div>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <app-revised-plan></app-revised-plan>
      </div>
    </div>
  </div>
</div>
