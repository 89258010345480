import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';
import {FormDataService} from "../services/form-data.service";
import {map} from "rxjs";
import {chatMessage} from "../models/chatMessage";
import {chatMessages} from "../models/chatMessages";
import {AvailableUserChoices} from "../models/enums";

@RepoPath('/chats')
@Injectable({
  providedIn: 'root'
})
export class ChatRepository extends BaseRepository<any>{
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService, private formDataConverter:FormDataService) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getChats() {
    return super.get();
  }
  @Path('/')
  newChat() {
    return super.post({type: "MGMT_COPILOT"});
  }
  @Path('/{chatId}/messages/')
  newMessage(chatId: string | null, message: string) {
    let data={userChoice: "NONE", textInput: message};
    let formData: FormData= this.formDataConverter.convertToFormData(data);
    return super.postFile(formData);
  }
  @Path('/{chatId}/messages/{messageId}')
  getMessage(chatId: string | null,messageId: string) {
    return super.get().pipe(
      map(data => new chatMessage(data)));
  }
  @Path('/{chatId}/messages')
  getMessages(chatId: string) {
    return super.get().pipe(
      map(data => new chatMessages(data)));
  }
  @Path('/{chatId}/messages/{messageId}')
  updateMessage(chatId: string,messageId: string, userChoice: AvailableUserChoices) {
    let data={userChoice: userChoice};
    return super.put(data);
  }
  @Path('/{chatId}')
  deleteChat(chatId:string) {
    return super.delete()
  }
  /*@Path('/{chatId}/messages/{messageId}/batch')
  getMessageBatch(chatId: string,messageId: string) {
    return super.get();
  }*/
  /*@Path('/{chatId}/messages/{messageId}/stream')
  getMessageStream(chatId: string,messageId: string) {
    return super.get();
  }*/


  public setUrl(url:string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }
  public unSetUrl(){
    this.repoUrl = undefined;
  }


}
