<div class="d-flex mb-3">
  <div *ngFor="let button of buttons" >
    <button
      class="badge"
      [ngClass]="{'badge-success': button.selected, 'badge-secondary': !button.selected}"
      (click)="toggleSelect(button)"
    >
      {{ button.label }}
    </button>
  </div>
</div>
<button type="submit" class="btn btn-primary me-2">Next</button>
