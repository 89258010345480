import { Component } from '@angular/core';
import {NgbAccordionModule} from "@ng-bootstrap/ng-bootstrap";
import {UserIntentComponent} from "../plannerComponents/user-intent/user-intent.component";
import {AvailablePluginsComponent} from "../plannerComponents/available-plugins/available-plugins.component";
import {InitialPlanComponent} from "../plannerComponents/initial-plan/initial-plan.component";
import {TaskDefinitionComponent} from "../plannerComponents/task-definition/task-definition.component";
import {PluginActionComponent} from "../plannerComponents/plugin-action/plugin-action.component";
import {
  PluginActionResponseComponent
} from "../plannerComponents/plugin-action-response/plugin-action-response.component";
import {TaskOutcomeComponent} from "../plannerComponents/task-outcome/task-outcome.component";
import {RevisedPlanComponent} from "../plannerComponents/revised-plan/revised-plan.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-planner',
  standalone: true,
  imports: [TranslateModule,NgbAccordionModule,UserIntentComponent,AvailablePluginsComponent,InitialPlanComponent,TaskDefinitionComponent, PluginActionComponent,PluginActionResponseComponent,TaskOutcomeComponent,RevisedPlanComponent],
  templateUrl: './planner.component.html',
  styleUrl: './planner.component.css'
})
export class PlannerComponent {

}
