import {Component, Input} from '@angular/core';
import {chatMessage} from "../../../models/chatMessage";

@Component({
  selector: 'app-assistant-message',
  standalone: true,
  imports: [],
  templateUrl: './assistant-message.component.html',
  styleUrl: './assistant-message.component.css'
})
export class AssistantMessageComponent {
  @Input() message: any;
}
