import {AvailableUserChoices, MessageStatus, MessageType, ToolingType} from './enums';

export class chatMessage {
  seq: number = 0;
  messageId: string = '';
  tooling: any;
  toolingType: ToolingType = ToolingType.RAG_AGENT;
  messageType: MessageType= MessageType.HUMAN_MESSAGE;
  availableUserChoices: AvailableUserChoices[] = [];
  userChoice: AvailableUserChoices= AvailableUserChoices.NONE;
  messageStatus: MessageStatus = MessageStatus.DEFAULT;
  memorySummary: string= '';
  rawData: string= '';
  attachment: string= '';

  constructor(data: any) {
    this.seq = data.seq ;
    this.messageId = data.messageId;
    this.tooling = data.tooling;
    this.toolingType = data.toolingType;
    this.messageType = data.messageType;
    this.availableUserChoices = data.availableUserChoices;
    this.userChoice = data.userChoice;
    this.messageStatus = data.messageStatus;
    this.memorySummary = data.memorySummary;
    this.rawData = data.rawData;
    this.attachment = data.attachment;
  }
}

