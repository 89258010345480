<div class="chat-bubble-container">
  <div class="chat-bubble">
    @if (message.rawData){
    <p>{{ message.rawData }}</p>}
    @else if (!message.rawData && message.messageStatus== MessageStatus.MESSAGE_FINALIZED){
      <p>{{ 'chat.noRawDataForFinalized' | translate}} </p>
      <p>{{ 'chat.userChoiceWas' | translate}}:  {{ 'tools.'+message.userChoice | translate}}    <span><i style="cursor: pointer"  (click)="jumpToTool(message.tooling.toolingId)" class="bi mr-2 bi-caret-right-fill"></i></span></p>
 }

    @else {
      <p>{{ 'chat.noRawData' | translate}} </p>
    }

  </div>
</div>
<div *ngFor="let tool of message.availableUserChoices" >
  <button class="btn btn-primary mt-2" (click)="toolSelector(tool,message.messageId)">{{ 'tools.'+tool | translate}}</button>
</div>

