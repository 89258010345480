<div class="mt-3">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'url'" (click)="setActiveTab('url')">URL</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'params'" (click)="setActiveTab('params')">Params</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'headers'" (click)="setActiveTab('headers')">Headers</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'body'" (click)="setActiveTab('body')">Body</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'plugin'" (click)="setActiveTab('plugin')">Plugin</a>
    </li>
  </ul>

  <div class="tab-content">
    <div *ngIf="activeTab === 'url'" class="tab-pane active mt-3">
      <form [formGroup]="urlForm">
        <div class="input-group">
          <input type="text" formControlName="url" class="form-control" placeholder="Enter URL">
        </div>
      </form>
    </div>

    <div *ngIf="activeTab === 'params'" class="tab-pane active mt-3">
      <form [formGroup]="paramsForm">
        <div formArrayName="params">
          <div *ngFor="let param of params.controls; let i = index" [formGroupName]="i" class="input-group mb-2">
            <input type="text" formControlName="key" class="form-control" placeholder="Key">
            <input type="text" formControlName="value" class="form-control" placeholder="Value">
            <button type="button" class="btn btn-danger" (click)="removeParam(i)">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="addParam()">Add Param</button>
      </form>
    </div>

    <div *ngIf="activeTab === 'headers'" class="tab-pane active mt-3">
      <form [formGroup]="headersForm">
        <div formArrayName="headers">
          <div *ngFor="let header of headers.controls; let i = index" [formGroupName]="i" class="input-group mb-2">
            <input type="text" formControlName="key" class="form-control" placeholder="Key">
            <input type="text" formControlName="value" class="form-control" placeholder="Value">
            <button type="button" class="btn btn-danger" (click)="removeHeader(i)">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="addHeader()">Add Header</button>
      </form>
    </div>

    <div *ngIf="activeTab === 'body'" class="tab-pane active mt-3">
      <form [formGroup]="bodyForm">
        <textarea formControlName="body" class="form-control" rows="5" placeholder="Enter body content"></textarea>
      </form>
    </div>

    <div *ngIf="activeTab === 'plugin'" class="tab-pane active mt-3">
      <!-- Plugin content here -->
    </div>
  </div>
  <button class="btn btn-primary mt-3" (click)="next()">Next</button>
</div>
