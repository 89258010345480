<div class=" mt-3">
  <div class="row w-100">
    @if (step?.action.rawData) {
      <div class="col-12">
        <span class="badge bg-info ms-auto">{{ 'rag.searchQuery' | translate }}</span><span
        class="ml-2">{{ step?.action.rawData }} </span>
      </div>
    }
  </div>
  <div class="container mt-3">
    <div class="row">
      @for (reference of step?.action?.references; track reference) {
        <div class="col-lg-4 col-md-6 col-sm-12 d-flex">
          <div class="card mb-4 flex-fill">
            <div class="card-body d-flex flex-column">
              <h2 class="card-title">{{ getConnectedDocumentTitle(reference.documentId) }}</h2>
              <p><strong>{{ 'rag.pageNumber' | translate }}: {{ reference.pageNumber }}, {{ 'rag.size' | translate }}: {{ reference.size }}</strong></p>
              <p><strong>{{ reference.summary }}</strong></p>
              <p>{{ reference.rawData }}</p>
              <button class="btn btn-primary mt-auto" (click)="openFile(reference.documentId, reference.pageNumber)">Open preview</button>
            </div>
          </div>
        </div>
      }

    </div>
  </div>
  @if(step.stepStatus== STEP_STATUS.USER_PENDING){
    <button type="submit" class="btn btn-primary me-2"  (click)="next()">Next</button>
  }
</div>
