import { Component } from '@angular/core';
import {NgClass, NgFor} from "@angular/common";

@Component({
  selector: 'app-available-plugins',
  standalone: true,
  imports: [NgFor, NgClass],
  templateUrl: './available-plugins.component.html',
  styleUrl: './available-plugins.component.css'
})
export class AvailablePluginsComponent {
  buttons = [
    { label: 'Button 1', selected: false },
    { label: 'Button 2', selected: false },
    { label: 'Button 3', selected: false }
  ];

  toggleSelect(button: any) {
    button.selected = !button.selected;
  }
}
