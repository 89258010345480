import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {JsonPipe, NgIf} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-answer-generation',
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    FormsModule,
    JsonPipe,
    NgIf
  ],
  templateUrl: './answer-generation.component.html',
  styleUrl: './answer-generation.component.css'
})
export class AnswerGenerationComponent {
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  protected readonly STEP_STATUS = STEP_STATUS;
  toolingId: string | null = "";
  constructor(private modalService: NgbModal, private ragRepository: RagRepository, private route: ActivatedRoute) {
  }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });
  }
  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }
}
