import { Component } from '@angular/core';
import {JsonPipe, NgFor, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-task-outcome',
  standalone: true,
  imports: [NgFor,NgIf,JsonPipe,FormsModule],
  templateUrl: './task-outcome.component.html',
  styleUrl: './task-outcome.component.css'
})
export class TaskOutcomeComponent {
  isEditing = false;
  jsonString = '{"name": "John", "age": 30, "city": "New York"}';
  parsedJson = JSON.parse(this.jsonString);
  editedJsonString = this.jsonString;

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (!this.isEditing) {
      this.editedJsonString = this.jsonString;
    }
  }

  save() {
    try {
      this.parsedJson = JSON.parse(this.editedJsonString);
      this.jsonString = this.editedJsonString;
      this.isEditing = false;
    } catch (error) {
      alert('Invalid JSON format');
    }
  }

  discard() {
    this.editedJsonString = this.jsonString;
    this.isEditing = false;
  }

  next() {

  }
}
