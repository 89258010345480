<div class="modal-header">
  @if (template.templateId) {
  <h5 class="modal-title">{{"template.edit" | translate}}</h5>
  } @else {
  <h5 class="modal-title">{{"template.addNew" | translate}}</h5>
  }

  <button type="button" class="close unhighlight" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">

    <div class="form-group">
      <label for="label">{{"template.label" | translate}}</label>
      <input type="text" id="label" class="form-control" formControlName="label" required/>
    </div>
    <div class="form-group">
      <label for="description">{{"template.description" | translate}}</label>
      <input type="text" id="description" class="form-control" formControlName="description" required/>
    </div>
    <div class="form-group">
      <label for="rawData">{{"template.rawdata" | translate}}</label>
      <textarea id="rawData" class="form-control mt-2" formControlName="rawData" required></textarea>
    </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
        <button type="submit" class="btn btn-primary"
                [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
      </div>
  </form>
</div>

