import { Component } from '@angular/core';
import { ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-user-intent',
  standalone: true,
  imports: [ReactiveFormsModule,NgIf],
  templateUrl: './user-intent.component.html',
  styleUrl: './user-intent.component.css'
})
export class UserIntentComponent {
  isEditing = false;
  markdownText = 'Your **markdown** text here...';
  editedText = this.markdownText;

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (!this.isEditing) {
      this.editedText = this.markdownText;
    }
  }

  save() {
    this.markdownText = this.editedText;
    this.isEditing = false;
  }

  discard() {
    this.editedText = this.markdownText;
    this.isEditing = false;
  }
}
