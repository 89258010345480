import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {KeycloakService} from "../auth/keycloak.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private timer: any;
  private timeoutPeriod = environment.exitPeriod; // 5 minutes in milliseconds

  // Subject to notify components when timer is reset
  private timerResetSubject = new Subject<void>();

  timerReset$ = this.timerResetSubject.asObservable(); // Observable for components to subscribe to

  constructor(private router: Router, private keycloakService: KeycloakService) {
    this.startTimer();
  }

  // Method to start the timer
  startTimer() {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.logout();
    }, this.timeoutPeriod);
  }

  // Method to reset the timer
  resetTimer() {
    this.startTimer();
    this.timerResetSubject.next();
    this.keycloakService.renewToken().then(() => {
     // console.log('Token renewed');

    }).catch((error: unknown) => {
     // console.error('Renew failed:', error);
    });
  }

  // Clear the timer
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.keycloakService.renewToken().then(() => {
       // console.log('Token renewed');

      }).catch((error: unknown) => {
        //console.error('Renew failed:', error);
      });
    }
  }

  // Logout method
  logout() {
    this.clearTimer();
    this.keycloakService.logout().then(() => {
      console.log('Logged out successfully');

    }).catch((error: unknown) => {
      console.error('Logout failed:', error);
    });
    console.log('User logged out due to inactivity');
  }
}
