<div class="modal-header">
  <h5 class="modal-title">{{"document.partition."+partition.elementType+"Edit" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" *ngIf="partition">

    <div class="form-group">
      <label>{{"document.partition.type" | translate}}</label>
      <div class="radio-group">
        <div class="radio-item">
          <input type="radio" id="typeText" formControlName="elementType" value="text" required />
          <label for="typeText">{{'document.partition.textRadio' | translate}}</label>
        </div>
        <div class="radio-item">
          <input type="radio" id="typeTitle" formControlName="elementType" value="title" required />
          <label for="typeTitle">{{'document.partition.titleRadio' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="summary">{{"document.partition.summary" | translate}}</label>
      <input type="text" id="summary" class="form-control" formControlName="summary" required />
      <label for="textInput">{{"document.partition."+partition.elementType | translate}}</label>
      <textarea *ngIf="partition.elementType=='text'" id="textInput" class="form-control mt-2" formControlName="textInput" required></textarea>
      <input *ngIf="partition.elementType=='title'" type="text" id="textInput" class="form-control" formControlName="textInput" required />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>
