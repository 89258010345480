import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {environment} from '../../environments/environment';

export class Utilities {
  static isProduction() {
    return environment.production;
  }
  static handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  static getServiceUrl() {
    return environment.serviceUrl;
  }

}
