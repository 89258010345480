import {Component, Input} from '@angular/core';
import {chatMessage} from "../../../models/chatMessage";

@Component({
  selector: 'app-human-message',
  standalone: true,
  imports: [],
  templateUrl: './human-message.component.html',
  styleUrl: './human-message.component.css'
})

export class HumanMessageComponent {
  @Input() message: any;

}
