<div class="modal-header">
  <h5 class="modal-title">{{"document.partition."+partition.elementType+"Edit" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center">
    <div *ngIf="base64Image" class="card" style="width: 18rem;">
      <img [src]="base64Image" class="card-img-top" alt="Image">
    </div>
  </div>
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" *ngIf="partition">
    <div class="form-group">
      <label for="summary">{{"document.partition.summary" | translate}}</label>
      <input type="text" id="summary" class="form-control" formControlName="summary" required />
    </div>
    <div class="form-group">
      <label for="fileInput">{{"document.partition."+partition.elementType | translate}}</label>
      <input type="file" id="fileInput" class="form-control" (change)="onFileSelected($event)" accept=".png, .jpg, .jpeg, .svg" required />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>
