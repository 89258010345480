<div class="mt-3">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'status'" (click)="setActiveTab('status')">Status</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'response'" (click)="setActiveTab('response')">Response</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'headers'" (click)="setActiveTab('headers')">Headers</a>
    </li>
  </ul>

  <div class="tab-content">
    <div *ngIf="activeTab === 'status'" class="tab-pane active mt-3">
      <p>HTTP Response Status: {{ httpResponse.status }}</p>
    </div>

    <div *ngIf="activeTab === 'response'" class="tab-pane active mt-3">
      <pre>{{ httpResponse.body | json }}</pre>
    </div>

    <div *ngIf="activeTab === 'headers'" class="tab-pane active mt-3">
      <ul>
        <li *ngFor="let header of getHeaders()">
          {{ header.key }}: {{ header.value }}
        </li>
      </ul>
    </div>
  </div>
  <button class="btn btn-primary mt-3" (click)="next()">Next</button>
</div>
