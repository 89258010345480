import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {JsonPipe} from "@angular/common";
import {PdfViewerComponent} from "../../pdf-viewer/pdf-viewer.component";
import {DocumentRepository} from "../../../repositories/document.repository";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../services/notification.service";
import {RagRepository} from "../../../repositories/rag.repository";
import {STEP_STATUS} from "../../../models/enums";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-retrieval',
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    JsonPipe
  ],
  templateUrl: './retrieval.component.html',
  styleUrl: './retrieval.component.css'
})
export class RetrievalComponent {
  @Input() step: any;
  toolingId: string | null = "";
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  constructor(private modalService: NgbModal, private ragRepository: RagRepository,private docRepository: DocumentRepository, private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });
  }

  next() {
    this.step.stepStatus = "USER_FINALIZED";
    if (this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }

  openFile(documentId: any, page: any) {
    const modalRef = this.modalService.open(PdfViewerComponent, {size: 'xl'});
    modalRef.componentInstance.documentId = documentId;
    modalRef.componentInstance.page = page;
  }

  async getConnectedDocumentTitle(documentId: string) {
    /*let document= await lastValueFrom(this.docRepository.getDocument(documentId));
    return document.documentId;*/
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}
