import {ChangeDetectorRef, Component, Input, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DocumentRepository} from "../../../repositories/document.repository";
import {NotificationService} from "../../../services/notification.service";
import {Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {Partition} from "../../../models/documentModel";
import {lastValueFrom} from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-partition-text-edit',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgIf],
  templateUrl: './partition-text-edit.component.html',
  styleUrl: './partition-text-edit.component.css'
})
export class PartitionTextEditComponent {
  @Input() partition!: any ;
  @Input() documentId!: any ;
  @Input() partitionId!: any ;
  uploadForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private docRepository: DocumentRepository,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.uploadForm = this.fb.group({
      textInput: ['', Validators.required],
      summary: ['', Validators.required],
      elementType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.uploadForm.patchValue({
      textInput: this.partition.rawData || '',
      summary: this.partition.semanticSummary|| '',
      elementType: this.partition.elementType || '',
    });
  }

  onSubmit(): void {
    if (this.uploadForm.valid) {
      console.log(this.uploadForm);
      const {textInput} = this.uploadForm.value;
      const {summary} = this.uploadForm.value;
      const {elementType} = this.uploadForm.value;
      this.docRepository.patchPartition(this.documentId,this.partitionId,  elementType, textInput, summary).subscribe(
        (response) => {
          console.log('Upload successful', response);
          this.notificationService.showSuccess("Siker", "document.partition.successPatch");
          this.activeModal.close(true);
         this.router.navigate([this.router.url]).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Upload failed', error);
        }
      );
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
}
