import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import {from, map, Observable} from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private keycloakAuth: any;

  constructor() {}

  initKeycloak(): Promise<boolean> {
    this.keycloakAuth = new Keycloak({
      url: environment.keycloakUrl,
      realm: environment.keycloakRealm,
      clientId: environment.keycloakClientId,
    });

    return this.keycloakAuth.init({ onLoad: 'login-required',  checkLoginIframe: false , tokenMinValidity: 30})
      .then((authenticated: boolean) => {
        console.log(`Initialized Keycloak, authenticated: ${authenticated}`);
        return authenticated;
      }).catch((error: any) => {
        console.error('Error during Keycloak initialization', error);
        throw error;
      });
  }


  isLoggedIn(): Observable<boolean> {
    return from(Promise.resolve(!!this.keycloakAuth.token && !this.keycloakAuth.isTokenExpired()));
  }


  login() {
    return this.keycloakAuth.login();
  }

  logout() {
    return this.keycloakAuth.logout();
  }

  getProfile() {
    return this.keycloakAuth.loadUserProfile();
  }

  getToken(): Promise<string> {
    return Promise.resolve(this.keycloakAuth.token);
  }

  renewToken(): Promise<string> {
    return Promise.resolve(this.keycloakAuth.updateToken());
  }
}
