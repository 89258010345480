import { Component } from '@angular/core';
import {CdkDragDrop, DragDropModule, moveItemInArray} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgFor, NgIf} from "@angular/common";

@Component({
  selector: 'app-revised-plan',
  standalone: true,
  imports: [DragDropModule,ReactiveFormsModule,NgFor,FormsModule,NgIf],
  templateUrl: './revised-plan.component.html',
  styleUrl: './revised-plan.component.css'
})
export class RevisedPlanComponent {
  items = [
    { id: 1, label: 'Item 1', isEditing: false },
    { id: 2, label: 'Item 2', isEditing: false },
    { id: 3, label: 'Item 3', isEditing: false },
    { id: 4, label: 'Item 4', isEditing: false },
    { id: 5, label: 'Item 5', isEditing: false }
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  editItem(item: any) {
    item.isEditing = true;
  }

  saveItem(item: any) {
    item.isEditing = false;
  }

  discardEdit(item: any) {
    item.isEditing = false;
    item.tempLabel = item.label;
  }
  next() {
    // Implement next button functionality
    console.log('Next button clicked');
  }
}
