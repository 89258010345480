import { Component } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgFor, NgIf} from "@angular/common";

@Component({
  selector: 'app-plugin-action',
  standalone: true,
  imports: [ReactiveFormsModule,NgIf,NgFor],
  templateUrl: './plugin-action.component.html',
  styleUrl: './plugin-action.component.css'
})
export class PluginActionComponent {
  activeTab = 'url';

  urlForm: FormGroup;
  paramsForm: FormGroup;
  headersForm: FormGroup;
  bodyForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.urlForm = this.fb.group({
      url: ['']
    });

    this.paramsForm = this.fb.group({
      params: this.fb.array([])
    });

    this.headersForm = this.fb.group({
      headers: this.fb.array([])
    });

    this.bodyForm = this.fb.group({
      body: ['']
    });
  }

  get params() {
    return this.paramsForm.get('params') as FormArray;
  }

  get headers() {
    return this.headersForm.get('headers') as FormArray;
  }

  addParam() {
    this.params.push(this.fb.group({
      key: [''],
      value: ['']
    }));
  }

  removeParam(index: number) {
    this.params.removeAt(index);
  }

  addHeader() {
    this.headers.push(this.fb.group({
      key: [''],
      value: ['']
    }));
  }

  removeHeader(index: number) {
    this.headers.removeAt(index);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  next() {

  }
}
