import { Component } from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-task-definition',
  standalone: true,
  imports: [ReactiveFormsModule,NgIf],
  templateUrl: './task-definition.component.html',
  styleUrl: './task-definition.component.css'
})
export class TaskDefinitionComponent {
  isEditing = false;
  markdownText = 'Task definition';
  editedText = this.markdownText;

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (!this.isEditing) {
      this.editedText = this.markdownText;
    }
  }

  save() {
    this.markdownText = this.editedText;
    this.isEditing = false;
  }

  discard() {
    this.editedText = this.markdownText;
    this.isEditing = false;
  }
}
