import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {AuthGuard} from "./auth/auth.guard";
import {LoginComponent} from "./pages/login/login.component";
import {DocumentListComponent} from "./pages/document-list/document-list.component";
import {DocumentDetailPageComponent} from "./pages/document-detail-page/document-detail-page.component";
import {DocumentChunkEditComponent} from "./pages/document-chunk-edit/document-chunk-edit.component";
import {ChatPageComponent} from "./pages/chat-page/chat-page.component";
import {PromtPageComponent} from "./pages/promt-page/promt-page.component";
import {PluginPageComponent} from "./pages/plugin-page/plugin-page.component";
import {PlannerPageComponent} from "./pages/planner-page/planner-page.component";
import {RagPageComponent} from "./pages/rag-page/rag-page.component";


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: DocumentListComponent, canActivate: [AuthGuard] },
  { path: 'templates', component: PromtPageComponent, canActivate: [AuthGuard]},
  { path: 'chat', component: ChatPageComponent, canActivate: [AuthGuard]},
  { path: 'chat/:id', component: ChatPageComponent, canActivate: [AuthGuard]},
  { path: 'documents/:id', component: DocumentDetailPageComponent, canActivate: [AuthGuard]},
  { path: 'documents/:id/edit-chunk/:chunkid', component: DocumentChunkEditComponent, canActivate: [AuthGuard]},
  { path: 'plugins', component: PluginPageComponent, canActivate: [AuthGuard]},
  { path: 'planner', component: PlannerPageComponent, canActivate: [AuthGuard]},
  { path: 'rag', component: RagPageComponent, canActivate: [AuthGuard]},
  { path: 'chat/:chatId/rag/:ragId', component: RagPageComponent, canActivate: [AuthGuard]},
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // Redirect to `dashboard` as default
  { path: '**', redirectTo: '/dashboard' } // Catch-all route
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
