<div class=" mt-3">
  <div cdkDropList (cdkDropListDropped)="drop($event)" class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of items" cdkDrag>
      <div class="d-flex align-items-center" *ngIf="!item.isEditing">
        <i class="bi bi-grip-vertical me-2" cdkDragHandle></i>
        {{ item.label }}
      </div>
      <div class="d-flex align-items-center" *ngIf="item.isEditing">
        <i class="bi bi-grip-vertical me-2" cdkDragHandle></i>
        <textarea [(ngModel)]="item.label" class="form-control me-2"></textarea>
      </div>
      <div>
        <i *ngIf="!item.isEditing" class="bi bi-pencil" (click)="editItem(item)" style="cursor: pointer;"></i>
        <div *ngIf="item.isEditing">
          <i class="bi bi-check" (click)="saveItem(item)" style="cursor: pointer; margin-right: 5px;"></i>
          <i class="bi bi-x" (click)="discardEdit(item)" style="cursor: pointer;"></i>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary mt-3" (click)="next()">Next</button>
</div>
