import { Component } from '@angular/core';
import {JsonPipe, NgFor, NgIf} from "@angular/common";

@Component({
  selector: 'app-plugin-action-response',
  standalone: true,
  imports: [NgIf,JsonPipe,NgFor],
  templateUrl: './plugin-action-response.component.html',
  styleUrl: './plugin-action-response.component.css'
})
export class PluginActionResponseComponent {
  activeTab = 'status';

  httpResponse = {
    status: 200,
    body: {
      message: 'Success',
      data: { key1: 'value1', key2: 'value2' }
    },
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    } as { [key: string]: string }
  };

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  getHeaders() {
    return Object.keys(this.httpResponse.headers).map(key => ({
      key: key,
      value: this.httpResponse.headers[key]
    }));
  }

  next() {

  }
}
